import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  station: IScanningStation
  scan: IVisitScanResult
}

function image(visitor: IVisitScanResult['visitor'], instanceId: string): string {
  if (visitor.type === 'resident') {
    return getResidentImageSrc(instanceId, visitor.imageId, visitor.sex, 'thumbnail')
  } else {
    return getUserImageSrc(instanceId, visitor.imageId, visitor.sex, 'thumbnail')
  }
}

export const VisitorSuccessCard: React.FC<Props> = ({ scan, station }) => {
  const context = React.useContext(AppContext)
  const bgColor = scan.type === 'in' ? 'bg-green-500' : 'bg-indigo-500'
  const borderColor = scan.type === 'in' ? 'border-green-500' : 'border-indigo-500'
  const textColor = scan.type === 'in' ? 'text-green-500' : 'text-indigo-500'
  const cardColor = scan.type === 'in' ? 'bg-green-100' : 'bg-indigo-100'
  let arrow: 'left' | 'right' = scan.type === 'out' ? 'left' : 'right'
  if (station.entrance === 'left') {
    arrow = scan.type === 'out' ? 'right' : 'left'
  }

  return (
    <div className='flex'>
      <div className='flex-[0_0_10%] relative' style={{ fontSize: 100 }}>
        {arrow === 'left' && (
          <div
            className={`absolute top-[50%] h-[100px] left-0 right-0 transform -translate-y-1/2 ${textColor}`}
          >
            <i className='absolute top-0 right-0 fas fa-caret-left' />
          </div>
        )}
      </div>
      <div className='flex-[1_1_10%] relative'>
        <div
          className={`absolute top-[50%] left-0 right-0 transform -translate-y-1/2 h-6 w-full ${bgColor}`}
        />
      </div>

      <div className='flex-[0_1_500px]  flex flex-col min-w-0'>
        <div
          className={`flex flex-content border-4 rounded-lg min-h-[240px] ${borderColor} ${cardColor}`}
        >
          <div className='flex-[0_0_40%] p-4'>
            <img
              className='rounded-md'
              src={image(scan.visitor, context.instance.id)}
              alt={scan.visitor.lastName}
            />
          </div>
          <div className='flex-auto py-4 pr-4 min-w-0' style={{ fontSize: 24 }}>
            <div className='truncate'>{`${scan.visitor.lastName}, ${scan.visitor.firstName}`}</div>

            {scan.visitor.type === 'resident' && (
              <>
                <div className='text-base'>{`Herkunft: ${
                  scan.visitor.country?.country ||
                  scan.visitor.country?.nationality ||
                  '-'
                }`}</div>

                <div className='text-base'>{`Unterkunft: ${
                  scan.visitor.accommodation?.label || '-'
                }`}</div>
              </>
            )}
            {scan.visitor.type === 'user' && (
              <div className='inline-flex items-center rounded-md bg-indigo-500 px-2 py-1 text-xs font-medium text-white'>
                {scan.visitor.sex === 'female' ? 'Mitarbeiterin' : 'Mitarbeiter'}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='flex-[1_1_10%] relative'>
        <div
          className={`absolute top-[50%] left-0 right-0 transform -translate-y-1/2 h-6 w-full ${bgColor}`}
        />
      </div>
      <div className='flex-[0_0_10%] relative' style={{ fontSize: 100 }}>
        {arrow === 'right' && (
          <div
            className={`absolute top-[50%] h-[100px] left-0 right-0 transform -translate-y-1/2 ${textColor}`}
          >
            <i className='absolute top-0 left-0 fas fa-caret-right' />
          </div>
        )}
      </div>
    </div>
  )
}
