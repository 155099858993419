import * as React from 'react'
import dayjs from 'dayjs'
import styles from '../styles.module.scss'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { AppContext } from 'services/connection/models/AppContext'
import { Link } from 'react-router-dom'
import { getResidentUrl } from 'helpers/getResidentUrl'
import Entrance from '../images/in.png'
import Exit from '../images/out.png'
import { bookingTypes, HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { SearchIcon } from '@heroicons/react/outline'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'

interface Props {
  scan: IVisitScanResult
}

@observer
export class VisitorTile extends React.Component<Props, {}> {
  static contextType = AppContext

  get image(): string {
    if (this.props.scan.visitor.type === 'resident') {
      return getResidentImageSrc(
        this.context.instance.id,
        this.props.scan.visitor.imageId,
        this.props.scan.visitor.sex,
        'thumbnail',
      )
    } else {
      return getUserImageSrc(
        this.context.instance.id,
        this.props.scan.visitor.imageId,
        this.props.scan.visitor.sex,
        'thumbnail',
      )
    }
  }

  /*isCardValid = () => {
    if (this.props.visitor.cardIsValid === null) return null
    return this.props.visitor.cardIsValid
      ? <span className='inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800'>Ausweis gültig</span>
      : <span className='inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800'>Ausweis ungültig</span>
  }*/

  render() {
    const visitor = this.props.scan.visitor
    const scannedAt = dayjs(this.props.scan.scannedAt)
    const accommodation = this.props.scan.visitor.accommodation
    const bookingType = bookingTypes[accommodation?.type || ''] || {
      label: '',
      color: '',
      icon: SearchIcon,
    }

    return (
      <div className={`card card-body relative ${styles.tile}`}>
        <img
          src={this.image}
          alt=''
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 120,
            height: 120,
            borderRadius: '0 0 4px 0',
          }}
        />
        <div className='absolute' style={{ top: 5, left: 130, height: 120, width: 180 }}>
          <div className='mb-2 flex'>
            <div className='flex-auto text-slate-500 f6 mr-auto text-sm'>
              <i className='fas fa-barcode mr-1' />
              <span>
                {scannedAt.isSame(new Date(), 'day')
                  ? scannedAt.format('HH:mm')
                  : scannedAt.format('DD.MM.')}
              </span>
            </div>
            <div className='flex-content text-xs'>{/*this.isCardValid()*/}</div>
          </div>

          {this.props.scan.type === 'in' && (
            <div style={{ margin: '5px 0' }}>
              <img src={Entrance} className='inline' width='20' height='20' alt='' />
              <span className='text-green-500 ml-1' style={{ position: 'relative' }}>
                Eintritt
              </span>
              &nbsp;
            </div>
          )}

          {this.props.scan.type === 'out' && (
            <div style={{ margin: '5px 0' }}>
              <img src={Exit} className='inline' width='20' height='20' alt='' />
              <span className='text-red-500 ml-1' style={{ position: 'relative' }}>
                Austritt
              </span>
            </div>
          )}

          <div className='truncate'>
            {this.props.scan.visitor.type === 'resident' ? (
              <Link to={getResidentUrl(this.props.scan.visitor.id)}>
                {visitor.lastName.toUpperCase()}
                <br />
                {visitor.firstName}
              </Link>
            ) : (
              <span>
                {visitor.lastName.toUpperCase()}
                <br />
                {visitor.firstName}
              </span>
            )}
          </div>
        </div>
        <div style={{ margin: '130px 10px 10px 0px' }}>
          {accommodation && (
            <div className={`${styles.residence} flex`}>
              <div className='flex-content mr-1'>
                <bookingType.icon
                  className={`h-4 w-4 ${bookingType.color} rounded-full`}
                  style={{ padding: 1 }}
                  aria-hidden='true'
                />
              </div>
              {accommodation.reason === 'Hausverbot' && (
                <div className='flex-content mr-1'>
                  <HouseBan />
                </div>
              )}
              <div className='flex-auto overflow-hidden'>{accommodation.label}</div>
            </div>
          )}
          {this.props.scan.visitor.type === 'resident' && !accommodation && (
            <div className={styles.residence}>Nicht eingebucht</div>
          )}
          {this.props.scan.visitor.type === 'user' && (
            <div className='inline-flex items-center rounded-md bg-indigo-500 px-2 py-1 text-xs font-medium text-white'>
              {this.props.scan.visitor.sex === 'female' ? 'Mitarbeiterin' : 'Mitarbeiter'}
            </div>
          )}
        </div>
      </div>
    )
  }
}
