import { hermes } from '@byll/hermes'
import { Age } from 'components/Age'
import { Spinner } from 'components/Spinner'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { IVisitor } from 'contracts/scan/interfaces/IVisitor'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  compoundId: string
}

@observer
export class Visitors extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref visitors: IVisitor[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    const data = await hermes.getOnceNew<{ id: string; visitors: IVisitor[] }>(
      `/api/${this.context.instance.id}/visitors/${this.props.compoundId}`,
    )
    runInAction(() => (this.visitors = data.visitors))
  }

  private mapVisitor = (visitor: IVisitor) => (
    <tr key={visitor.id}>
      <td className='px-6 py-4 whitespace-nowrap'>
        <div className='flex items-center'>
          <div className='flex-shrink-0 h-10 w-10'>
            <img
              className='h-10 w-10 rounded-full'
              src={getResidentImageSrc(
                this.context.instance.id,
                visitor.imageId,
                visitor.sex,
                'thumbnail',
              )}
              alt=''
            />
          </div>
          <div className='ml-4'>
            <div className='text-sm font-medium text-gray-900'>{`${visitor.lastName.toUpperCase()}, ${
              visitor.firstName
            }`}</div>
            <div className='text-sm text-gray-500'>
              <Age sex={visitor.sex} dateOfBirth={visitor.dateOfBirth} />
            </div>
          </div>
        </div>
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-sm'>
        {visitor.dateOfBirth ? dayjs(visitor.dateOfBirth).format('DD.MM.YYYY') : ''}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
        {visitor.location}
      </td>
      <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
        {visitor.id.startsWith('U') ? (
          <span className='inline-flex items-center rounded-md bg-indigo-500 px-2 py-1 text-xs font-medium text-white'>
            {visitor.sex === 'female' ? 'Mitarbeiterin' : 'Mitarbeiter'}
          </span>
        ) : (
          <Link
            to={`/residents/${visitor.jbpId.toLowerCase()}/overview`}
            className='text-indigo-600 hover:text-indigo-900'
          >
            Akte öffnen
          </Link>
        )}
      </td>
    </tr>
  )

  render() {
    const compound = hermes.getFromStore<ICompound>(
      `/api/${this.context.instance.id}/accommodations/compounds/${this.props.compoundId}`,
      false,
    )
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full'>
        {/* Section heading */}
        <div className='relative my-7 bg-white sm:rounded-lg shadow p-6'>
          <div className='md:flex md:items-center md:justify-between'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Anwesende Bewohner&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{compound?.label}
            </h3>
          </div>
        </div>

        {/* Table */}
        <div className='flex flex-col'>
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Name
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Geburtsdatum
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Aufenthaltsdetails
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {this.visitors && this.visitors.map(this.mapVisitor)}
                  </tbody>
                </table>

                {!this.visitors && <Spinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
